<template>
    <v-card>
        <v-card-text>
            <v-row>
                <v-col cols="4">
                    <v-card class="pa-3" outlined max-width="300">
                        <apex-radial-chart value="Fair"></apex-radial-chart>
                        <h3 class="text-center text-uppercase">Credit</h3>
                    </v-card>
                </v-col>
                <v-col cols="4">
                    <v-card class="pa-3" outlined max-width="300">
                        <apex-radial-chart value="Poor"></apex-radial-chart>
                        <h3 class="text-center text-uppercase">Crime</h3>
                    </v-card>
                </v-col>
                <v-col cols="4">
                    <v-card class="pa-3" outlined max-width="300">
                        <apex-radial-chart value="Poor"></apex-radial-chart>
                        <h3 class="text-center text-uppercase">Evictions</h3>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="4"
                    v-if="
                        applicant.incomes.length > 0 ||
                        applicant.employers.length > 0
                    "
                >
                    <v-card class="pa-3" outlined max-width="300">
                        <apex-radial-chart value="Fair"></apex-radial-chart>
                        <h3 class="text-center text-uppercase">Income</h3>
                    </v-card>
                </v-col>
                <v-col cols="4" v-if="applicant.addresses.length > 0">
                    <v-card class="pa-3" outlined max-width="300">
                        <apex-radial-chart value="Poor"></apex-radial-chart>
                        <h3 class="text-center text-uppercase">
                            Rental References
                        </h3>
                    </v-card>
                </v-col>
                <v-col cols="4" v-if="applicant.employers.length > 0">
                    <v-card
                        class="pa-3"
                        outlined
                        disabled
                        color="grey lighten-4"
                        max-width="300"
                    >
                        <apex-radial-chart value=""></apex-radial-chart>
                        <h3
                            class="text-center text-uppercase grey--text text--darken-2"
                        >
                            Employment References
                        </h3>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "order-summary",
    components: {
        ApexRadialChart: () => import("@components/lib/Charts/ApexRadialChart"),
    },
    props: {
        applicant: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>